import React from "react";
import Modal from "react-bootstrap/Modal";
const MaintainanceModal = ({ show, handleMaintainanceModalClose }) => {
  return (
    <Modal show={show} onHide={handleMaintainanceModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Maintenance </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-5 p-4 mb-1">
          <h4>
            We're currently fine-tuning our platform for an even better
            experience. During this brief period, new bids, Deposits and
            Withdrawals cannot be accepted or processed. We appreciate your
            patience and understanding.
          </h4>
          <h4>Stay tuned, and we'll have things up and running shortly!</h4>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MaintainanceModal;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ManualField from "../../pages/crash/manualField";
import "./crash.css";
import infoIcon from "../../assets/info.svg";

import { unit } from "../../utils/index";
import { Layout } from "../layout/layout";
import BetContent from "../../components/Crash/BetContent";
import explode from "../../assets/animationGif/crash.png";
import Chart from "../../components/Chart";
import queryString from "query-string";

import { MdMusicNote, MdMusicOff } from "react-icons/md";

import { RektGameSplash } from "../splash";
import {
  bid,
  cashout,
  getBalance,
  getGameConfig,
  getUserBetHistory,
  login,
} from "../../components/config/axios";

import useSound from "use-sound";

import { socket } from "../../components/config/socket";
import toast from "react-hot-toast";
import { Header } from "../header/header";
import BetHistory from "../../components/BetHistory";

import StartAudio from "../../assets/audio/main.mp3";
import WinAudio1 from "../../assets/audio/win1.mp3";
import WinAudio2 from "../../assets/audio/win2.mp3";
import WinAudio3 from "../../assets/audio/win3.mp3";
import LoseAudio from "../../assets/audio/lose.mp3";
import MaintainanceModal from "../../components/Modals/MaintainanceModal";
import RestrictionModal from "../../components/Modals/RestrictionModal";
import CashoutInfoModal from "../../components/Modals/CashoutInfoModal";

const CrashGame = () => {
  const [gameStartMusic, { stop: stopGameStartMusic, sound: gameStartSound }] =
    useSound(StartAudio, {
      volume: 0.6,
    });

  const [winMusic1, { stop: stopWinMusic1 }] = useSound(WinAudio1);

  const [winMusic2, { stop: stopWinMusic2 }] = useSound(WinAudio2);

  const [winMusic3, { stop: stopWinMusic3 }] = useSound(WinAudio3);

  const [loseGameMusic, { stop: stopLoseGameMusic }] = useSound(LoseAudio, {
    volume: 0.6,
  });

  const [gameConfig, setGameConfig] = useState(
    JSON.parse(localStorage.getItem("game-config"))
  );

  let minBetValue = Number(gameConfig?.min_bid_amount);
  let maxBetValue = Number(gameConfig?.max_bid_amount);

  let minCashoutValue = Number(gameConfig?.min_cashout);
  let maxCashoutValue = Number(gameConfig?.max_cashout);

  let incrementDecrementBy = 0.1;

  let isUserAllowed = gameConfig?.is_allowed_to_play ?? null;
  let isGamePaused = gameConfig?.status === "PAUSED" ?? null;

  let dollarMultiplier = gameConfig?.dollar_price ?? 0;

  const [isMuted, setIsMuted] = useState(true);

  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [userBidData, setUserBidData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [betValue, setBetValue] = useState(minBetValue || 1);
  const [cashoutValue, setCashoutValue] = useState(0);

  const [bettedValue, setBettedValue] = useState(0);
  const [cashedOutValue, setCashedOutValue] = useState(0);

  const [isPlayClicked, setIsPlayClicked] = useState(false);

  const parsed = queryString.parse(window.location.search);

  const { name: user, identifier: id } = parsed;

  const [balance, setBalance] = useState(
    JSON.parse(localStorage.getItem("balance"))
  );
  const [isExploding, setIsExploding] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentOdd, setCurrentOdd] = useState("");
  const [testMode, setTestMode] = useState(
    localStorage.getItem("testmode") === "true"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [splash, setSplash] = useState(true);
  const [isCashedOut, setIsCashedOut] = useState(false);

  const [isCashedOutClicked, setIsCashedOutClicked] = useState(false);

  const [userBetHistory, setUserBetHistory] = useState(
    JSON.parse(localStorage.getItem("user-history"))
  );

  const [show, setShow] = useState(false);

  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [showRestrictionModal, setShowRestrictionModal] = useState(false);

  const [showCashoutInfoModal, setShowCashoutInfoModal] = useState(false);

  const [firstCall, setFirstCall] = useState(true);

  const [notAllowed, setNotAllowed] = useState(false);

  const [dollarValue, setDollarValue] = useState(
    betValue * dollarMultiplier || ""
  );
  const [dollarValueForBalance, setDollarValueForBalance] = useState(
    balance * dollarMultiplier || ""
  );

  const handleClose = () => setShow(false);
  const handleShow = (isNav) => {
    isNav === "nav" && window.scrollTo(0, 5);
    isUserAllowed && !notAllowed && setShow(true);
  };

  const handleMaintainanceModalClose = () => setShowMaintenanceModal(false);
  const handleRestrictionModalClose = () => setShowRestrictionModal(false);

  const handleCashoutInfoModalClose = () => setShowCashoutInfoModal(false);

  const handleBet = async () => {
    if (!isUserAllowed) {
      return;
    }

    if (Number(cashoutValue) === 1) {
      toast.error(`Auto Cashout value can't be equal to 1 x`);
      setCashoutValue(0);
      return;
    }

    setBettedValue(betValue);
    setCashedOutValue(cashoutValue);

    const bidValue = {
      bid_amount: Number(Math.floor(betValue * 100) / 100),
      cashout: Number(Math.floor(cashoutValue * 100) / 100),
      game_id: localStorage.getItem("gameId"),
    };

    if (Number(cashoutValue) === 0) {
      bidValue.is_auto_cashout = true;
    }

    if (betValue === 0) {
      toast.error("Bet amount cannot be 0");
    } else if (betValue > balance?.amount) {
      toast.error("Insufficient balance");
    } else if (cashoutValue > 0 && cashoutValue < 1) {
      toast.error(`Invalid Auto Cashout (minimum ${minCashoutValue} x)`);
      setCashoutValue(minCashoutValue.toFixed(2));
    } else if (betValue < minBetValue) {
      toast.error(`Minimum bet amount allowed is ${minBetValue} ${unit}`);
      setBetValue(minBetValue);
    } else {
      bid({ data: bidValue }).then((res) => {
        if (res?.data?.status === "SUCCESS") {
          setIsPlayClicked(true);
          setIsPlaying(true);
          setIsLoading(true);
        }
      });
    }
  };
  const handleBetValue = (amt, checkValidation, balAmt) => {
    if (!isUserAllowed || gameStarted) return;

    // Prevent entering negative values so using if-else for vals >=0
    if (amt >= 0) {
      if (!checkValidation && amt > balAmt) {
        setBetValue(balAmt < maxBetValue ? balAmt : maxBetValue);
        setDollarValue(
          balAmt < maxBetValue
            ? dollarMultiplier * balAmt
            : dollarMultiplier * maxBetValue
        );
      } else if (amt > maxBetValue) {
        toast.error(`Maximum bet amount allowed is ${maxBetValue} ${unit}`);
        setBetValue(maxBetValue);
        setDollarValue(dollarMultiplier * maxBetValue);
      } else if (!checkValidation && amt <= minBetValue) {
        setBetValue(minBetValue);
        setDollarValue(dollarMultiplier * minBetValue);
      } else if (!checkValidation) {
        setBetValue(amt);
        setDollarValue(dollarMultiplier * amt);
      } else {
        setBetValue(amt);
        setDollarValue(dollarMultiplier * amt);
      }
    } else {
      setBetValue("");
    }
  };

  const handleOnBlur = (e) => {
    const { value } = e.target;
    if (!value || Number(value) < minBetValue) {
      toast.error(`Minimum bet amount allowed is ${minBetValue} ${unit}`);
      setBetValue(minBetValue); // setting again from nothing to min_val now.

      setDollarValue(dollarMultiplier * minBetValue);
    }
  };

  const handleCashOut = async () => {
    if (!isUserAllowed) {
      return;
    }

    setIsCashedOutClicked(true);

    const cashoutValue = {
      bid_amount: bettedValue,
      cashout: currentOdd,
      game_id: localStorage.getItem("gameId"),
    };

    cashout({ data: cashoutValue }).then((res) => {
      if (res?.data?.status === "SUCCESS") {
        const winValue = bettedValue * currentOdd - bettedValue;
        toast.success(`You won ${winValue?.toFixed(2)} ${unit} `);
        setIsPlaying(false);

        setIsCashedOut(true);
      }
    });

    gameStartSound?.fade(0, 0.6, 1500);

    const winAudios = [winMusic1, winMusic2, winMusic3];
    const randomIndex = Math.floor(Math.random() * winAudios.length);

    !isMuted && winAudios[randomIndex]();
  };

  const fetchBetHistory = () => {
    getUserBetHistory({ page: 1, pageSize: 10 }).then((res) => {
      setUserBetHistory(res.data);
    });
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);

    stopLoseGameMusic();
    stopGameStartMusic();
    stopWinMusic1();
    stopWinMusic2();
    stopWinMusic3();
  };

  useEffect(() => {
    if (id) {
      login({ identifier: id }).then((res) => {
        setUserData(res.data);
      });
    }
    if (user) {
      localStorage.setItem("user", user);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
    if (userData && isUserAllowed)
      getBalance().then((res) => {
        setBalance(res.data);
        setDollarValueForBalance(dollarMultiplier * res?.data?.amount);
      });
  }, [userData, isUserAllowed]);

  useEffect(() => {
    socket.on("gamestate", (data) => {
      if (data) {
        setGameStarted(data.isStarted);
        setGameOver(data.isCrashed);
      }
    });
    socket.on("user_bids", (data) => {
      if (data) {
        setUserBidData(data.user_bid_data);
      }
    });
  }, []);

  useEffect(() => {
    if (!gameStarted && gameOver) {
      setIsLoading(true);
    }
    if (!gameStarted && !gameOver) {
      setIsLoading(false);
    }
    if (gameStarted) {
      setIsLoading(false);
      setIsCashedOut(false);
    }
    if (gameOver) {
      setIsPlaying(false);
      setIsPlayClicked(false);

      setBettedValue(0);
      setCashedOutValue(0);

      setIsCashedOutClicked(false);

      !isMuted && !splash && loseGameMusic();

      if (userData && isUserAllowed) fetchBetHistory();

      getGameConfig({ userId: id, firstCall })
        .then((res) => {
          setGameConfig(res?.data);
          setFirstCall(false);
          setDollarValue(res?.data?.dollar_price * betValue);
          setDollarValueForBalance(res?.data?.dollar_price * balance?.amount);
        })
        .catch((error) => {
          console.error("Error fetching game config:", error);
          setGameConfig(null); // or any default value you prefer
          setFirstCall(false);
        });
    }
  }, [gameStarted, gameOver]);

  useEffect(() => {
    setTimeout(() => {
      setSplash(false);
    }, 3000);
  }, []);

  useEffect(() => {
    getGameConfig({ userId: id, firstCall })
      .then((res) => {
        setGameConfig(res?.data);
        setFirstCall(false);

        setDollarValue(res?.data?.dollar_price * betValue);
        setDollarValueForBalance(res?.data?.dollar_price * balance?.amount);
      })
      .catch((error) => {
        console.error("Error fetching game config:", error);
        setGameConfig(null); // or any default value you prefer
        setFirstCall(false);
      });
  }, []);

  useEffect(() => {
    if (userData && isUserAllowed) fetchBetHistory();
  }, [userData, isUserAllowed]);

  useEffect(() => {
    if (gameStarted && !isMuted && !splash) {
      gameStartMusic();
    } else {
      stopGameStartMusic();
    }
    return () => {
      stopGameStartMusic();
    };
  }, [gameStarted, isMuted, splash]);

  useEffect(() => {
    if (isUserAllowed !== null && !isUserAllowed) {
      setShowRestrictionModal(true);
      setNotAllowed(true); // once not allowed then not allowed for all games
    }
  }, [isUserAllowed]);

  useEffect(() => {
    if (isGamePaused !== null && isGamePaused) {
      setShowRestrictionModal(false);
      setShowMaintenanceModal(true);
    }
  }, [isGamePaused]);

  if (splash) {
    return <RektGameSplash />;
  }

  return (
    <>
      <Header
        balance={balance}
        showHistory={handleShow}
        dollarValue={dollarValueForBalance}
      />
      <Layout>
        <div className="game-page">
          <div className="container">
            <div className="game-body">
              <button className="mute-btn" onClick={toggleMute}>
                {isMuted ? <MdMusicOff size={30} /> : <MdMusicNote size={30} />}
              </button>

              <div className="game-bet-box ">
                <div className="target-tabs-data">
                  <div className="crash-tab-manual">
                    <ManualField
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      betValue={betValue}
                      setBetValue={setBetValue}
                      cashoutValue={cashoutValue}
                      setCashoutValue={setCashoutValue}
                      handleBet={handleBet}
                      gameStarted={gameStarted}
                      handleCashOut={handleCashOut}
                      gameOver={gameOver}
                      handleBetValue={handleBetValue}
                      handleOnBlur={handleOnBlur}
                      testMode={testMode}
                      isLoading={isLoading}
                      balance={balance}
                      minBetValue={minBetValue}
                      maxBetValue={maxBetValue}
                      minCashoutValue={minCashoutValue}
                      maxCashoutValue={maxCashoutValue}
                      incrementDecrementBy={incrementDecrementBy}
                      isUserAllowed={isUserAllowed}
                      isPlayClicked={isPlayClicked}
                      isGamePaused={isGamePaused}
                      isNotAllowed={notAllowed}
                      dollarValue={dollarValue}
                      setShowCashoutInfoModal={setShowCashoutInfoModal}
                    />
                  </div>

                  <BetContent
                    cashOutdata={userBidData}
                    currentOdd={currentOdd}
                    betValue={betValue}
                    gameOver={gameOver}
                    gameStarted={gameStarted}
                    isCashedOut={isCashedOut}
                    handleShowHistory={handleShow}
                    id={id}
                    isUserAllowed={isUserAllowed}
                    isGamePaused={isGamePaused}
                    isNotAllowed={notAllowed}
                  />

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <div className="d-flex gap-1 align-items-center">
                          <span>Bet History</span>
                          <div
                            onClick={() => setShowCashoutInfoModal(true)}
                            className="icon-wrapper"
                          >
                            <img src={infoIcon} alt="infoIcon" />
                          </div>
                        </div>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <BetHistory
                        betHistory={userBetHistory}
                        setUserBetHistory={setUserBetHistory}
                      />
                    </Modal.Body>
                  </Modal>
                  <MaintainanceModal
                    show={showMaintenanceModal}
                    handleMaintainanceModalClose={handleMaintainanceModalClose}
                  />
                  <RestrictionModal
                    show={showRestrictionModal}
                    handleRestrictionModalClose={handleRestrictionModalClose}
                  />

                  <CashoutInfoModal
                    show={showCashoutInfoModal}
                    handleCashoutInfoModalClose={handleCashoutInfoModalClose}
                  />
                </div>
              </div>
              <div className="game-screen">
                <Chart
                  currentOdd={currentOdd}
                  setCurrentOdd={setCurrentOdd}
                  setBalance={setBalance}
                  setIsPlaying={setIsPlaying}
                  isPlaying={isPlaying}
                  setIsCashedOut={setIsCashedOut}
                  isMuted={isMuted}
                  stopGameStartMusic={stopGameStartMusic}
                  winMusic1={winMusic1}
                  winMusic2={winMusic2}
                  winMusic3={winMusic3}
                  gameStartSound={gameStartSound}
                  isUserAllowed={isUserAllowed}
                  bettedValue={bettedValue}
                  cashedOutValue={cashedOutValue}
                  isCashedOutClicked={isCashedOutClicked}
                  dollarMultiplier={dollarMultiplier}
                  setDollarValueForBalance={setDollarValueForBalance}
                />
                {isExploding && (
                  <div className="crash-explode">
                    <img src={explode} alt="" />
                    <h4>REKT</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default CrashGame;

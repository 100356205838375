/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { PulseLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import { getUserBetHistory } from "../config/axios";
import { getDateTime } from "../../utils";

const profitColor = "#00E06F";
const lossColor = "#FF483C";
const lightText = "#667694";

const columns = [
  {
    name: "Time",
    selector: (row) => {
      let { date, time } = getDateTime(row.created_at);
      return (
        <span className="d-flex flex-column gap-2">
          <span>{date}</span>
          <span style={{ color: lightText }}>{time}</span>
        </span>
      );
    },
  },
  {
    name: "Bet",
    cell: (row) =>
      row.is_profit ? (
        <span
          style={{ display: "flex", alignItems: "center", minWidth: "30px" }}
        >
          <span>{Number(row.bid_amount).toFixed(2)}</span>
          {/* ▲ */}
        </span>
      ) : (
        <span style={{ display: "flex", alignItems: "center" }}>
          <span>{Number(row.bid_amount).toFixed(2)}</span>
          {/* ▼ */}
        </span>
      ),
  },

  {
    name: "Cashout",
    selector: (row) =>
      row.cashout > 0 ? `${Number(row.cashout).toFixed(2)}x` : "0x",
  },
  {
    name: "Payout",
    cell: (row) => (
      <div className="payout-cell d-flex align-items-center flex-column gap-2">
        <div
          style={{
            backgroundColor:
              row.result && row.result === "won" ? profitColor : lossColor,
            minWidth: "58px",
            borderRadius: "16px",
            padding: "3px 8px",
            textAlign: "center",
          }}
        >
          <span style={{ color: "#262c3b" }}>
            {row.result && row.result === "won"
              ? `${
                  row.amount_won
                    ? `+${Number(row.amount_won)?.toFixed(2)}`
                    : "-"
                }`
              : `${
                  row.amount_lost ? Number(row.amount_lost)?.toFixed(2) : "-"
                }`}
          </span>
        </div>
        {row.game_crash ? `${Number(row.game_crash).toFixed(2)}x` : "-"}
      </div>
    ),
  },
];
const BetHistory = ({ betHistory }) => {
  const [betHistoryData, setBetHistoryData] = useState(betHistory?.data ?? []);
  const [betHistoryMetaData, setBetHistoryMetaData] = useState(
    betHistory?.metadata
  );

  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 10;

  const fetchMoreData = () => {
    setTimeout(() => setCurrentPage(currentPage + 1), 1000);
  };

  useEffect(() => {
    if (currentPage > 1) {
      getUserBetHistory({ page: currentPage, pageSize })
        .then((res) => {
          setBetHistoryData([...betHistoryData, ...res.data.data]);
          setBetHistoryMetaData(res.data.metadata);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [currentPage]);

  if (!betHistory || betHistory.length === 0) {
    return <h2 className="text-center">User Not Found!</h2>;
  }

  return (
    <div
      id="scrollableDiv"
      className="bet-history"
      style={{ maxHeight: "540px", overflowY: "scroll", borderRadius: "12px" }}
    >
      <InfiniteScroll
        scrollableTarget="scrollableDiv"
        dataLength={betHistoryData.length}
        next={fetchMoreData}
        hasMore={betHistoryMetaData?.has_next_page ?? false}
        loader={
          <span className="d-flex justify-content-center">
            <PulseLoader color="#36d7b7" size={8} />
          </span>
        }
        endMessage={
          betHistoryData.length > 11 ? (
            <p className="text-center m-1">No more Bets</p>
          ) : (
            ""
          )
        }
      >
        <DataTable columns={columns} data={betHistoryData} />
      </InfiniteScroll>
    </div>
  );
};

export default BetHistory;

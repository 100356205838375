import React from "react";
import Modal from "react-bootstrap/Modal";
const RestrictionModal = ({ show, handleRestrictionModalClose }) => {
  return (
    <Modal show={show} onHide={handleRestrictionModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Game Restricted</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-5 p-4 mb-1">
          <h4>
            Due to geographical restrictions, access to this application is
            currently limited. Please feel free to contact our support team
          </h4>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RestrictionModal;

import React from "react";
import "./style.css";
import face2 from "../../assets/splash-face2.png";

export const RektGameSplash = () => {
  return (
    <div className="REKT-game-splash">
      <div className="overlap-group-wrapper">
        <div className="overlap-group">
          <div className="rectangle" />
          <img
            className="bitcoin"
            alt="Bitcoin"
            src="https://c.animaapp.com/ACYFPU2F/img/bitcoin.svg"
          />
          <img
            className="frame"
            alt="Frame"
            src="https://c.animaapp.com/ACYFPU2F/img/frame.svg"
          />
          <img className="image" alt="faceImg" src={face2} />
          <img
            className="img"
            alt="Bitcoin2"
            src="https://c.animaapp.com/ACYFPU2F/img/bitcoin-2.svg"
          />
          <img
            className="group"
            alt="Group"
            src="https://c.animaapp.com/ACYFPU2F/img/group-55@2x.png"
          />
          <img
            className="bitcoin-2"
            alt="Bitcoin3"
            src="https://c.animaapp.com/ACYFPU2F/img/bitcoin-1.svg"
          />
          <p className="text-wrapper">TO YOLO OR NOT TO YOLO</p>
          <img
            className="frame-2"
            alt="Frame"
            src="https://c.animaapp.com/ACYFPU2F/img/frame-1.svg"
          />
        </div>
      </div>
    </div>
  );
};

import React from "react";
import toast from "react-hot-toast";
import { Button, Form, InputGroup } from "react-bootstrap";
import arrowup from "../../assets/arrow-up.png";
import arrowdown from "../../assets/arrow-down.png";
import infoIcon from "../../assets/info.svg";

const ManualField = ({
  isPlaying,
  setIsPlaying,
  activeBox,
  betValue,
  handleBet,
  cashoutValue,
  setCashoutValue,
  gameStarted,
  handleCashOut,
  gameOver,
  handleBetValue,
  handleOnBlur,
  testMode,
  isLoading,
  balance,
  minBetValue,
  maxBetValue,
  minCashoutValue,
  maxCashoutValue,
  incrementDecrementBy,
  isUserAllowed,
  isPlayClicked,
  isGamePaused,
  isNotAllowed,
  dollarValue,
  setShowCashoutInfoModal,
}) => {
  const handleChangeCashout = (value) => {
    if (!isUserAllowed || gameStarted) return;

    if (value >= 0) {
      if (value > 0 && value < 1) {
        toast.error(`Minimum Auto Cashout allowed is ${minCashoutValue} x`);
        setCashoutValue(0);
      } else if (value > maxCashoutValue) {
        toast.error(`Maximum Auto Cashout allowed is ${maxCashoutValue} x`);
        setCashoutValue(maxCashoutValue);
      } else {
        // added a condition for value 2.3 -> its give 2.29 after flooring it using math..
        if (Number(value) === 2.3) {
          setCashoutValue(value);
          return;
        }
        setCashoutValue(value ? Math.floor(value * 100) / 100 : "");
      }
    } else {
      setCashoutValue("");
    }
  };

  const handleIncDecCashout = (value, plus) => {
    if (!isUserAllowed || gameStarted) return;

    // 2 special cases for 0 and 1 handling on inc/dec
    if (Number(cashoutValue) === 0 && plus) {
      setCashoutValue(minCashoutValue);
      return;
    }
    if (Number(cashoutValue) === minCashoutValue && !plus) {
      setCashoutValue(0);
      return;
    }

    if (value <= 0) {
      setCashoutValue(0);
    } else if (value === 1) {
      setCashoutValue(minCashoutValue);
    } else if (value > maxCashoutValue) {
      setCashoutValue(maxCashoutValue);
    } else {
      setCashoutValue(value.toFixed(2));
    }
  };

  const handleCashoutOnBlur = (e) => {
    const { value } = e.target;

    if (Number(value) <= 0) {
      setCashoutValue(0);
      return;
    }
  };

  const hideNumPad = (e) => {
    e.preventDefault();
    e.target.blur();
  };

  const handleKeyUp = (event, ...extraKeys) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      hideNumPad(event);
    }

    let disallowedChars = ["-", "+", "e", "E"];
    if (extraKeys.length > 0) {
      disallowedChars = [...disallowedChars, ...extraKeys];
    }
    if (disallowedChars?.includes(event.key)) {
      event.preventDefault();
    }
  };

  const changeBtnText = (isUserAllowed) => {
    if (gameStarted && !gameOver) {
      if (testMode) {
        return (
          <Button
            onClick={handleCashOut}
            disabled={!isUserAllowed || isGamePaused || isNotAllowed}
          >
            Cashout
          </Button>
        );
      }
      return (
        <Button
          onClick={handleCashOut}
          disabled={
            !isPlaying || !isUserAllowed || isGamePaused || isNotAllowed
          }
        >
          <> {!isPlaying ? "Play" : "Cashout"}</>
        </Button>
      );
    }

    if (gameStarted) {
      setIsPlaying(false);
    }
    return (
      <Button
        onClick={handleBet}
        disabled={isLoading || !isUserAllowed || isGamePaused || isNotAllowed}
      >
        {isLoading ? "Waiting" : "Play"}
      </Button>
    );
  };

  const getBetValue = (value) => {
    // added a condition for value 2.3 -> its give 2.29 after flooring it using math..
    if (Number(value) === 2.3) {
      return value;
    }

    if (value) {
      return Math.floor(value * 100) / 100;
    }
    return "";
  };

  return (
    <div className={`rekt box-section ${activeBox ? "disable-bet" : ""}`}>
      <div className="manual-tab">
        <Form>
          <div className="bet-btn mb-3">{changeBtnText(isUserAllowed)}</div>
          <div className="rekt-bet-box">
            <Form.Group className="form-group-manual mb-0">
              <div className="form-bet-container">
                <div className="bet-input pdd-200">
                  <Form.Label>Bet Amount</Form.Label>

                  <div className="position-relative">
                    <Form.Control
                      className="form-bet-input"
                      type="number"
                      placeholder={`${minBetValue || 0}`}
                      value={getBetValue(betValue)}
                      onChange={(e) => handleBetValue(e.target.value, true)}
                      onBlur={handleOnBlur}
                      onKeyUp={handleKeyUp}
                      min={minBetValue || 0}
                      max={balance || maxBetValue || 0}
                      disabled={
                        !isUserAllowed ||
                        gameStarted ||
                        isPlayClicked ||
                        isGamePaused ||
                        isNotAllowed
                      }
                    />
                    <span className="dollar-value-input">
                      {dollarValue
                        ? `~$${Math.floor(dollarValue * 100) / 100}`
                        : "$0"}
                    </span>
                  </div>
                </div>
                <div className="bet-input-btn">
                  <Button
                    disabled={
                      !isUserAllowed ||
                      gameStarted ||
                      isPlayClicked ||
                      isGamePaused ||
                      isNotAllowed
                    }
                    onClick={() =>
                      handleBetValue(
                        Number(betValue) / 2,
                        false,
                        balance?.amount
                      )
                    }
                  >
                    1/2
                  </Button>
                  <Button
                    disabled={
                      !isUserAllowed ||
                      gameStarted ||
                      isPlayClicked ||
                      isGamePaused ||
                      isNotAllowed
                    }
                    className="bet-2x-btn"
                    onClick={() =>
                      handleBetValue(
                        Number(betValue) * 2,
                        false,
                        balance?.amount
                      )
                    }
                  >
                    2x
                  </Button>
                  <Button
                    disabled={
                      !isUserAllowed ||
                      gameStarted ||
                      isPlayClicked ||
                      isGamePaused ||
                      isNotAllowed
                    }
                    className="bet-max-btn"
                    onClick={() =>
                      handleBetValue(
                        Number(balance?.amount) < maxBetValue
                          ? balance?.amount
                          : maxBetValue,
                        false,
                        balance?.amount
                      )
                    }
                  >
                    Max
                  </Button>
                </div>
              </div>
            </Form.Group>

            <Form.Group className="form-group-manual">
              <div className="form-bet-container">
                <div className="bet-input pdd-200">
                  <div className="d-flex gap-1 ">
                    <Form.Label>Auto Cashout At</Form.Label>
                    <div
                      onClick={() => setShowCashoutInfoModal(true)}
                      className="icon-wrapper d-none"
                    >
                      <img src={infoIcon} alt="infoIcon" />
                    </div>
                  </div>

                  <Form.Control
                    type="number"
                    placeholder="0"
                    className="form-cashout-input"
                    value={cashoutValue}
                    onChange={(e) => handleChangeCashout(e.target.value)}
                    onKeyUp={handleKeyUp}
                    onBlur={handleCashoutOnBlur}
                    min={0}
                    disabled={
                      !isUserAllowed ||
                      gameStarted ||
                      isPlayClicked ||
                      isGamePaused ||
                      isNotAllowed
                    }
                  />
                </div>
                <div className="action-bet">
                  <Button
                    disabled={
                      !isUserAllowed ||
                      gameStarted ||
                      isPlayClicked ||
                      isGamePaused ||
                      isNotAllowed
                    }
                    className="cashout-minus-btn"
                    onClick={() =>
                      handleIncDecCashout(
                        +cashoutValue - incrementDecrementBy,
                        false
                      )
                    }
                  >
                    <img src={arrowdown} alt="arrow-down" />
                  </Button>
                  <Button
                    disabled={
                      !isUserAllowed ||
                      gameStarted ||
                      isPlayClicked ||
                      isGamePaused ||
                      isNotAllowed
                    }
                    className="cashout-plus-btn"
                    onClick={() =>
                      handleIncDecCashout(
                        +cashoutValue + incrementDecrementBy,
                        true
                      )
                    }
                  >
                    <img src={arrowup} alt="arrow-up" />
                  </Button>
                </div>
              </div>
            </Form.Group>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ManualField;

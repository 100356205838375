import "../../pages/crash/crash.css";

const UserIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_195_4891)">
        <path
          d="M9.38456 12.3306C8.48874 11.3244 7.93418 9.93103 7.93418 8.39136C7.93418 6.85169 8.48874 5.45831 9.38456 4.45213C8.8688 4.16611 8.28866 4.00549 7.6753 4.00549C5.53067 4.00549 3.7921 5.96911 3.7921 8.39136C3.7921 10.8136 5.53067 12.7772 7.6753 12.7772C8.28866 12.7772 8.8688 12.6166 9.38456 12.3306Z"
          fill="#FF3C98"
        />
        <path
          d="M8.9697 8.39136C8.9697 5.96911 10.7083 4.00549 12.8529 4.00549C14.9975 4.00549 16.7361 5.96911 16.7361 8.39136C16.7361 10.8136 14.9975 12.7772 12.8529 12.7772C10.7083 12.7772 8.9697 10.8136 8.9697 8.39136Z"
          fill="#FF3C98"
        />
        <path
          d="M16.3212 4.45213C17.2171 5.45831 17.7716 6.85169 17.7716 8.39136C17.7716 9.93103 17.2171 11.3244 16.3212 12.3306C16.837 12.6166 17.4171 12.7772 18.0305 12.7772C20.1751 12.7772 21.9137 10.8136 21.9137 8.39136C21.9137 5.96911 20.1751 4.00549 18.0305 4.00549C17.4171 4.00549 16.837 4.16611 16.3212 4.45213Z"
          fill="#FF3C98"
        />
        <path
          d="M5.86314 18.9174C5.86314 16.4952 7.60171 14.5316 9.74634 14.5316H15.9595C18.1041 14.5316 19.8427 16.4952 19.8427 18.9174C19.8427 21.3397 18.1041 23.3033 15.9595 23.3033H9.74634C7.60171 23.3033 5.86314 21.3397 5.86314 18.9174Z"
          fill="#FF3C98"
        />
        <path
          d="M0.685547 17.7479C0.685547 15.3256 2.42411 13.362 4.56874 13.362H9.74634C7.02981 13.362 4.82762 15.8493 4.82762 18.9174C4.82762 20.1161 5.16373 21.2261 5.73531 22.1338H4.56874C2.42411 22.1338 0.685547 20.1701 0.685547 17.7479Z"
          fill="#FF3C98"
        />
        <path
          d="M20.8782 18.9174C20.8782 20.1161 20.5421 21.2261 19.9705 22.1338H21.137C23.2817 22.1338 25.0202 20.1701 25.0202 17.7479C25.0202 15.3256 23.2817 13.362 21.137 13.362H15.9595C18.676 13.362 20.8782 15.8493 20.8782 18.9174Z"
          fill="#FF3C98"
        />
      </g>
      <defs>
        <clipPath id="clip0_195_4891">
          <rect
            width="24.8525"
            height="28.0696"
            fill="white"
            transform="translate(0.425781 0.204346)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;

/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import UserIcon from "./UserIcon";
import "../../pages/crash/crash.css";
import { unit } from "../../utils";
import explode from "../../assets/animationGif/crash.png";

const BetContent = ({
  classes,
  cashOutdata,
  currentOdd,
  gameOver,
  isCashedOut,
  handleShowHistory,
  id,
  isUserAllowed,
  isGamePaused,
  isNotAllowed,
}) => {
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    if (cashOutdata.length > 0) {
      let totalSum = 0;
      cashOutdata.map((data, index) => {
        totalSum += data?.bid_amount;
      });
      setTotalSum(totalSum);
    }
  }, [cashOutdata]);

  function getClass(userCashout, currUserId) {
    console.log(userCashout, currUserId, gameOver);
    let isUser = id === currUserId;

    if (isUser) {
      if (isCashedOut) {
        return "bet-user-amount-success";
      } else if (currentOdd > userCashout && userCashout > 0) {
        return "bet-user-amount-success";
      } else if (currentOdd > userCashout && userCashout === 0 && gameOver) {
        return "bet-user-amount-fail";
      } else if (currentOdd < userCashout && gameOver) {
        return "bet-user-amount-fail";
      } else if (currentOdd < userCashout && !gameOver) {
        return "bet-user-amount";
      }
    } else {
      if (currentOdd > userCashout && userCashout > 0) {
        return "bet-user-amount-success";
      } else if (currentOdd < userCashout && !gameOver) {
        return "bet-user-amount";
      } else if (currentOdd < userCashout && gameOver) {
        return "bet-user-amount-fail";
      } else if (Number(userCashout) === 0 && gameOver) {
        return "bet-user-amount-fail";
      } else {
        return "bet-user-amount";
      }
    }
  }

  return (
    <div className="bet-list">
      <div className="bet-amount">
        <span className={`${classes}`}>
          <UserIcon /> {cashOutdata?.length}{" "}
        </span>
        <span>
          {" "}
          <b>
            {totalSum ? Math.floor(totalSum * 100) / 100 : ""} {unit}
          </b>
        </span>
      </div>
      <ul>
        <>
          {cashOutdata.map((data, index) => {
            const isLastLiChild = index === cashOutdata.length - 1;

            return (
              <li
                key={index}
                className={`${id === data?.user_id ? "current-user-row" : ""}
                ${isLastLiChild && cashOutdata.length > 5 && "last-li-child"}
              `}
              >
                <div className="bet-user text-capitalize">
                  {id === data?.user_id && <img src={explode} alt="user" />}
                  <h5
                    className={id === data?.user_id ? "current-user-row" : ""}
                  >
                    {data?.user_name}
                  </h5>
                </div>
                <div className={getClass(data?.cashout, data?.user_id)}>
                  {data?.bid_amount
                    ? Math.floor(data?.bid_amount * 100) / 100
                    : ""}{" "}
                  {unit}
                </div>
              </li>
            );
          })}
        </>
      </ul>

      {isUserAllowed && !isGamePaused && !isNotAllowed && (
        <div className="bet-history-btn">
          <button onClick={handleShowHistory}>Bet history</button>
        </div>
      )}
    </div>
  );
};

export default BetContent;

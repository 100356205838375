import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import profile3 from "../../assets/animationGif/crash.png";
import icon from "../../assets/header-icon.png";
import { unit } from "../../utils";

export const Header = ({ balance, showHistory, dollarValue }) => {
  const [bal, setBal] = useState(balance);
  const [status, setStatus] = useState(null);

  const user = localStorage.getItem("user");

  useEffect(() => {
    if (bal?.amount > balance?.amount) {
      setStatus("fail");
    } else if (bal?.amount < balance?.amount) {
      setStatus("success");
    }
    setBal(balance);
  }, [balance]);

  return (
    <div className="header">
      <div className="container">
        <div className="header-content">
          <div className="logo">
            <img src={icon} alt="" />
          </div>

          <div className="headerUser">
            <span className="header_avatar" onClick={() => showHistory("nav")}>
              <img src={profile3} alt="avtar" />
            </span>
            <div className="headerUserContent">
              <p className="headerUserContentLabel text-capitalize">
                {user || "John Doe"}
              </p>
              <div
                className={
                  status === "success"
                    ? "headerUserBalanceSuccess"
                    : status === "fail"
                    ? "headerUserBalanceFail"
                    : "headerUserBalance"
                }
              >
                <span>
                  <span key={bal?._id}>
                    {bal?.amount ? Math.floor(bal?.amount * 100) / 100 : 0}{" "}
                    {unit}{" "}
                    {status === "success" ? "▲" : status === "fail" ? "▼" : ""}
                  </span>
                </span>
              </div>

              <span className="dollar-value-header">
                {dollarValue ? `~$${Math.floor(dollarValue * 100) / 100}` : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

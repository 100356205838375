import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";

const DynamicChart = ({ delay, started, crashed, launchTime }) => {
  const dRef = useRef(null);
  const lRef = useRef(null);
  const uRef = useRef(null);
  const jRef = useRef(null);
  const bRef = useRef(null);
  const hRef = useRef(null);
  const [f, setF] = useState(null);
  const [N, setN] = useState(null);
  const seconds = (Date.now() - (launchTime || 0)) / 1e3;

  const I = (e, t, a, c, n) => {
    const d1 = t.current?.getAttribute("d");
    const d2 = a.current?.getAttribute("d");

    c.to(e.current, { duration: 40, paused: n, attr: { d: d1 } }, "+=1")
      .to(e.current, { duration: 40, paused: n, attr: { d: d2 } }, "+=1")
      .to(e.current, { duration: 40, paused: n, attr: { d: d2 } }, "+=1");
  };

  useEffect(() => {
    if (started && crashed) {
      setF(gsap.timeline({ defaults: { duration: 1 }, paused: true }));
      setN(gsap.timeline({ defaults: { duration: 1 }, paused: true }));
    } else if (started) {
      setF(gsap.timeline({ defaults: { duration: 1 } }));
      setN(gsap.timeline({ defaults: { duration: 1 } }));
    }
  }, [started, crashed]);

  useEffect(() => {
    if (N && f) {
      if (!crashed) {
        const r = seconds >= 7 ? seconds - 7 : 0;
        N.seek(r);
        f.seek(r);
        I(dRef, jRef, hRef, f, crashed);
        I(lRef, uRef, bRef, N, crashed);
      } else {
        f.pause();
        N.pause();
      }
    }
  }, [crashed, seconds, N, f, jRef, hRef, lRef, uRef, bRef, dRef]);

  const getSVG = (e, t, a, c) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 790 516">
        <defs>
          <linearGradient
            id={c ? "lineGradient" : "pathGradient"}
            gradientTransform="rotate(80)"
          >
            <stop offset="100%" stopColor="#fd4a95" stopOpacity={c ? 1 : 0.4}>
              <animate
                attributeName="stopColor"
                values="#fd4a95"
                dur="3s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="100%" stopColor="#dd7dab20" stopOpacity={c ? 1 : 0}>
              <animate
                attributeName="stop-color"
                values="#dd7dab20"
                dur="3s"
                repeatCount="indefinite"
              ></animate>
            </stop>
          </linearGradient>
        </defs>
        <path
          ref={e}
          className={c ? "glc-l" : "glc-g"}
          d="M790,516H0L0,410.1C274.03013,325.17245,509.13669,190.059,701.62815,0H790Z"
        ></path>
        <path
          ref={t}
          d="M790,516H0L0,410.1C307.15109,408.69073,604.58475,296.45161,701.62815,0H790Z"
        ></path>
        <path
          ref={a}
          d="M790,516H0L0,410.1C395,410.1,701.62815,335.7353,701.62815,0H790Z"
        ></path>
      </svg>
    );
  };

  return (
    <div className="game-line-chart-holder absolute-splash" style={delay(0)}>
      <ul className="absolute-splash">
        <li className="absolute-splash">{getSVG(dRef, jRef, hRef, false)}</li>
        <li className="absolute-splash">{getSVG(lRef, uRef, bRef, true)}</li>
      </ul>
    </div>
  );
};

export default DynamicChart;

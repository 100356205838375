import { io } from "socket.io-client";
import { server } from "./keys";

const socket = io(server, {
	transports: ["websocket"],
	rejectUnauthorized: false,
	reconnection: false,
});
socket.on("connect", () => {
	console.log("connected");
});
socket.on("disconnect", () => {
	console.log("Disconnected");
});

const tryReconnect = () => {
	setTimeout(() => {
		console.log("reconnect");
		socket.io.open((err) => {
			if (err) {
				console.log("reconnect err => ", err);
				tryReconnect();
			} else {
				// re join
				console.log("connected with tryReconnect");
			}
		});
	}, 1000);
};
socket.io.on("close", tryReconnect);

export { socket };

import axios from "axios";
import { landingServer, server } from "../config/keys";
import { getCookie } from "../../utils/cookies";
import toast from "react-hot-toast";
export const getToken = () => {
  const tokenData = getCookie("token") ? getCookie("token") : "";
  return tokenData;
};

export const getAccessToken = () => {
  const token = JSON.parse(localStorage.getItem("userData")).access_token;
  return token;
};

export const getAuthorizationHeader = () => `Bearer ${getAccessToken()}`;

export const mineInstance = () =>
  axios.create({
    baseURL: `${server}/mines_api`,
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
export const authInstance = () =>
  axios.create({
    baseURL: `${landingServer}/users/login`,
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });

export const login = async ({ identifier }) => {
  const apiUrl = `${landingServer}/users/login`;
  const response = await axios.post(apiUrl, { identifier });
  return response.data;
};
export const bid = async ({ data }) => {
  try {
    const apiUrl = `${landingServer}/game/bid`;
    const response = await axios.post(apiUrl, data, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    toast.success(`Successfully entered`);
    return response.data;
  } catch (err) {
    console.log(err);
    if (err.response.status === 409) {
      toast.error("Bet is already in progress");
    }
    if (err.response.status === 406 || err.response.status === 412) {
      toast.error(err.response.data.errorData.message);
    }
  }
};

export const getBalance = async () => {
  try {
    const apiUrl = `${landingServer}/game/user/balance`;
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    localStorage.setItem("balance", JSON.stringify(response.data.data));
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const cashout = async ({ data }) => {
  try {
    const apiUrl = `${landingServer}/game/cashout`;
    const response = await axios.post(apiUrl, data, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getGameConfig = async ({ userId, firstCall }) => {
  try {
    const apiUrl = `${landingServer}/admin/game-config?user_id=${userId}&is_called_first_time=${firstCall}`;

    const response = await axios.get(apiUrl);

    localStorage.setItem("game-config", JSON.stringify(response.data.data));
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateGameConfig = async (data) => {
  try {
    const apiUrl = `${landingServer}/admin/game-config`;

    const response = await axios.post(apiUrl, data);

    localStorage.setItem("game-config", JSON.stringify(response.data.data));
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const getUserBetHistory = async ({ page, pageSize }) => {
  try {
    const apiUrl = `${landingServer}/game/bids`;

    const response = await axios.get(
      `${apiUrl}?page=${page}&page_size=${pageSize}`,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );

    localStorage.setItem("user-history", JSON.stringify(response.data.data));
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

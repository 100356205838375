import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import CrashGame from "./pages/crash/CrashGame";
import "bootstrap/dist/css/bootstrap.min.css";

import * as Sentry from "@sentry/react";

Sentry.init({
  environment: "production",
  dsn: "https://1e7bb0ea6b6a2fd876ef7a3bc428f660@o4507202345566208.ingest.us.sentry.io/4507202348711936",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.clear();
    };

    const handleTabClose = () => {
      clearLocalStorage();
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<CrashGame />} />
        <Route path="/:user/:id" element={<CrashGame />} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { Linear } from "gsap";
import { socket } from "../config/socket";

const gsapEase = Linear.easeInOut;

let debounce = 0;

const AbsoluteChart = ({ launchTime, animate }) => {
	const [svgElements, setSvgElements] = useState({
		xAxis: null,
		yAxis: null,
		gridLines: null,
	});

	const [currentOdd, setCurrentOdd] = useState(0);

	const kRef = useRef(null);

	const q = function (e) {
		return (Date.now() - e) / 1e3;
	};

	const E = () => {
		const svg = d3.select(kRef.current).append("svg");
		svg.attr("preserveAspectRatio", "xMinYMin meet").attr("viewBox", "0 0 690 450");
		setSvgElements({
			xAxis: svg.append("g").attr("class", "axis-x"),
			yAxis: svg.append("g").attr("class", "axis-y"),
			gridLines: svg.append("g").attr("class", "grid"),
		});
	};

	const F = (odd) => {
		// Extract the necessary values from the data
		const r = odd ? Number(odd) : Number(d3.scalePow().exponent(2 / 8)(q(launchTime)));

		const o = q(launchTime);

		const c = d3.scaleLinear();

		const n = d3.scaleLinear();
		const s = d3.axisLeft(c).ticks(5);
		const i = d3.axisBottom(n).ticks(5);
		c.domain([1, r <= 1.8 ? 1.8 : r]).range([0, -298]);
		n.domain([1, o <= 6 ? 6 : o]).range([0, 612]);

		// Transition and update the y-axis
		if (svgElements.yAxis) {
			svgElements.yAxis
				.attr("transform", "translate(50, 386)")
				.attr("font-size", 13)
				.attr("letter-spacing", 0.88)
				.attr("font-family", "'Lato', sans-serif")
				.attr("color", "#fff")
				.transition()
				.ease(gsapEase)
				.duration(100)
				.call(
					s
						.tickSize(0)
						.tickFormat((e) => (e === 0 ? "" : typeof e === "number" ? `${e.toFixed(1)}x` : ""))
				);
		}

		// Transition and update the x-axis
		if (svgElements.xAxis) {
			svgElements.xAxis
				.attr("transform", "translate(66, 412)")
				.attr("font-size", 13)
				.attr("letter-spacing", 0.88)
				.attr("font-family", "'Lato', sans-serif")
				.attr("color", "#fff")
				.transition()
				.ease(gsapEase)
				.duration(500)
				.call(i.tickSize(0));
		}

		// Transition and update the y-axis grid
		// if (svgElements.gridLines) {
		//   svgElements.gridLines
		//     .attr('transform', 'translate(66, 392)')
		//     .transition()
		//     .ease(gsapEase)
		//     .duration(500)
		//     .call(i.tickSize(-300).tickFormat(() => ''));
		// }
	};

	useEffect(() => {
		if (kRef.current) {
			d3.select(kRef.current).select("svg").remove();
			E();
			if (!animate) {
				F(0);
			}
		}
	}, [kRef, animate]);

	useEffect(() => {
		if (currentOdd) {
			F(currentOdd);
		}
	}, [currentOdd]);

	useEffect(() => {
		const handleCurrentOddUpdate = (data) => {
			if (document.visibilityState === "hidden" && debounce < 20) {
				debounce++;
			} else if (
				(document.visibilityState === "hidden" && debounce === 20) ||
				document.visibilityState === "visible"
			) {
				setCurrentOdd(data.currentOdd.toFixed(2));
				debounce = 0;
			}
		};

		const handleGamestateUpdate = (data) => {
			if (data.isCrashed) {
				setCurrentOdd(0);
			}
		};

		socket.on("currentOdd", handleCurrentOddUpdate);
		socket.on("gamestate", handleGamestateUpdate);

		return () => {
			socket.off("currentOdd", handleCurrentOddUpdate);
			socket.off("gamestate", handleGamestateUpdate);
		};
	}, []);

	return <div ref={kRef} className='chart absolute-splash' />;
};

export default React.memo(AbsoluteChart);

import "./layout.css";
import { Toaster } from "react-hot-toast";

export const Layout = ({ children }) => {
  return (
    <>
      <div className="crash-layout">
        <div className="crash-content">{children}</div>
      </div>
      <Toaster
        toastOptions={{
          duration: 4000,
          style: {
            minWidth: "fit-content",
            borderRadius: "12px",
            background: "#333",
            color: "#fff",
            padding: "8px",
            fontSize: "12px",
          },
        }}
      />
    </>
  );
};

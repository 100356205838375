import React from "react";
import Modal from "react-bootstrap/Modal";
const CashoutInfoModal = ({ show, handleCashoutInfoModalClose }) => {
  return (
    <Modal show={show} onHide={handleCashoutInfoModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-5 p-4 mb-1">
          <h4>
            1 in 16,000,000 chance REKTed and PAYOUT equal the same amount.
            Industry standard is for the result to be REKTed.
          </h4>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CashoutInfoModal;
